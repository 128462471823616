import React, { useState, useRef, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import styled, { createGlobalStyle } from 'styled-components';

import logo from './resources/hexAI_small.png'
import hexagon from './resources/hexagon.svg'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'Menlo', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    overflow: hidden;
  }
`

const Header = styled.div`
  box-sizing: border-box;
  width: 100%;
  display:flex;
  justify-content: left;
  align-items: center;
  padding: 6px;
  max-height: 58px;
  background-color: rgba(255, 255, 255, 0.6);;
  color: #000;  
  background-size: 100% 100%;
  border-bottom: 1px solid white;
`

const HeaderIcon= styled.img`
  width: 36px;
  pointer-events: none;
`

const HeaderTitle = styled.div`
  padding-left: 4px;
`

const GradientBackground = styled.div`
  background: linear-gradient(90deg, rgba(255,0,0,0.2) 0%, rgba(0,0,255,0.2) 100%);
  height: 100vh;
  width: 100vw;
`

const BoardFrame = styled.div`
`

const BoardRow = styled.div`
  display: flex;
`

const Hexag= styled.img`
  max-width: 64px;
  min-width: 32px;
`

interface boardState{
  hexagons: any;
}

function Title(){
  return (
    <Header>
      <HeaderIcon src={logo} alt={'hexAI-Logo'}/>
      <HeaderTitle>hexAI</HeaderTitle>
    </Header>
  );
}

function Hexagon(props){
  console.log(props.value);
  return (
    <img src={hexagon} alt={''} className={'hexagon'} onClick={props.onClick()}/>
  );
}

class Board extends React.Component<{}, boardState>{
  boardSize: number = 6;
  
  constructor(props){
    super(props);

    // Creating empty board
    var boardConstruction = [];
    for(var y: number = 0; y < this.boardSize; y++){
      var rowConstruction = [];

      for(var x: number = 0; x < this.boardSize; x++){
          rowConstruction.push(0);
      }
      boardConstruction.push(rowConstruction);
    }

    this.state = {
      hexagons: boardConstruction,
    };
  }

  handleClick(){
    console.log('click!');
  }

  renderBoard(){
    var boardConstruction = [];

    for(var y: number = 0; y < this.boardSize * 2; y++){
      var rowConstruction = [];

      if (y < this.boardSize) {
        for(var x: number = 0; x < y + 1; x++){
          rowConstruction.push(
            <Hexagon 
              value={this.state.hexagons[x][y]}
              onClick={() => this.handleClick()}
            />
          );
        }
      }
      // else{
      //   for(x = this.boardSize; x > 0 + 1; x--){
      //     rowConstruction.push(
      //       <Hexagon 
      //         value={this.state.hexagons[x][y]}
      //         onClick={() => this.handleClick()}
      //       />
      //     );
      //   }
      // }
      
      boardConstruction.push(<BoardRow>{rowConstruction}</BoardRow>);
    }

    return boardConstruction;
  }

  render() {
    return(
      <div className='board'>
        {this.renderBoard()}
      </div>
    );
  }
}

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle/>
    <GradientBackground>
      <Title/>
      <Board/>
    </GradientBackground>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();